<template>
  <div class="login">
    <div class="h40"></div>
    <div class="column ml25" ><img class="back" src="~images/fanhui.png" alt="" @click="onBack"></div>
    <div class="column padding">
      <login-tab @showlogin="showlogin" v-if="false"></login-tab>
      <!--密码登录
      <psw-login v-show="currentIndex == 0"></psw-login>-->
      <!--验证码登录-->
      <yanzheng-login ></yanzheng-login>
    </div>
    <div class="blank"></div>
  </div>
</template>

<script>
// @ is an alias to /src

import LoginTab from './childCmps/LoginTab'
import YanzhengLogin from './childCmps/YanzhengLogin'
import PswLogin from './childCmps/PswLogin'
export default {
  name: 'Login',
  data() {
    return {
      currentIndex: 0
    }
  },
  components: {
    LoginTab,
    YanzhengLogin,
    PswLogin
  },
  methods: {
    onBack() {
      this.$router.back();
    },
    showlogin(index){
      this.currentIndex = index
    },
  }
}
</script>
<style  scoped>
.login{
  background-color: #fff;
  height: 100vh;
}
.padding{
    padding: 0 .866667rem;
}
.back{
    width: .426667rem;
    height: .426667rem;
}
.underline{
    width:1.066667rem;
    height:.026667rem;
    background-color:rgba(246,35,65,1);
}
.input{
    border: none;
    outline: none;
    cursor: pointer;
}
.bubiao img{
    width: .426667rem;
    height: .426667rem;
}



</style>
